import React, { Suspense, useEffect, useRef } from "react";
import "../node_modules/aos/dist/aos.css";
import "./App.css";
import Navbar from "./components/Navbar";
import TelecomPage from "./Pages/TelecomPage";
import Home from "./Pages/HomePage";
import SmartCityPage from "./Pages/SmartCityPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import BlogPage from "./Pages/BlogPage";
import CareerPage from "./Pages/CareerPage";
import CareerIndividualPage from "./Pages/CareerIndividualPage";
import axios from "axios";
import { env } from "./config";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./redux/actions";
import Aos from "aos";
import NsepPage from "./Pages/NsepPage";
import ContactPage from "./Pages/ContactPage";
import AgroTechPage from "./Pages/AgroTechPage";
import IndividualBlog from "./Pages/IndividualBlog";
import IoTdDevelopmentPage from "./Pages/IoTdDevelopmentPage";
import FarmToPlatePage from "./Pages/FarmToPlatePage";
import SafetyAndMonitoringForBargesPage from "./Pages/SafetyAndMonitoringForBargesPage";
import ProductDevelopmentPage from "./Pages/ProductDevelopmentPage";
import ApplicationDevelopmentPage from "./Pages/ApplicationDevelopmentPage";
import EcommerceDevelopmentPage from "./Pages/EcommerceDevelopmentPage";
import CloudServicesPage from "./Pages/CloudServicesPage";
import QualityAssurancePage from "./Pages/QualityAssurancePage";
import TelecomSystemPage from "./Pages/TelecomSystemPage";
import ItConsultingPage from "./Pages/ItConsultingPage";
import DigitalTransfromationPage from "./Pages/DigitalTransfromationPage";
import ItConsultingHomePage from "./Pages/ItConsultingHomePage";
import CloudSolutionsPage from "./Pages/CloudSolutionsPage";
import DevopsandIntegrationPage from "./Pages/DevopsandIntegrationPage";
import EthnicDayPage from "./Pages/EthnicDayPage";
import CompanyPage from "./Pages/CompanyPage";
import FinancePage from "./Pages/FinancePage";
import Loader from "./components/Loader";
import DataAnalyticsPage from "./Pages/DataAnalyticsPage";
import Merchandize from "./Pages/Merchandize";
import MerchandizeIndividualPage from "./Pages/MerchandizeIndividualPage";
import WebsiteDevelopmentLandingPage from "./Pages/WebsiteDevelopmentLandingPage";
import MobileAppDevelopmentLandingPage from "./Pages/MobileAppDevelopmentLandingPage";
import WikipeedikaPage from "./Pages/WikipeedikaPage";
import WikipeedikaIndividualBlogPage from "./Pages/WikipeedikaIndividualBlogPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import NcsPage from "./Pages/NcsPage";
import NcsIndividualPage from "./Pages/NcsIndividualPage";
import NcsContact from "./Pages/NcsContact";
import { Toaster } from "react-hot-toast";
import PeojectCommunityCommerce from "./Pages/PeojectCommunityCommerce";
import OffshoreWindpage from "./Pages/OffshoreWindpage";
import Whitepaper from "./Pages/Whitepaper";
import WhitePaperIndividualPage from "./Pages/WhitePaperIndividualPage";
import CheckoutPage from "./Pages/CheckoutPage";

const Footer = React.lazy(() => import("./components/Footer"));

function App() {
  let { key } = useParams();
  const dispatch = useDispatch();
  const HomeData: any = useSelector(
    (state: any) => state?.Page?.HomePageContent
  );
  const BlogPages: any = useSelector((state: any) => state?.Page?.BlogContent);

  const getHomePageData = () => {
    axios
      .get(`${env}/api/home-page-content?populate=deep`)
      .then(function (response) {
        console.log(response.data.data.attributes);
        dispatch(Actions.HomePageAction(response?.data?.data?.attributes));
      });
  };
  const getBlogContent = () => {
    axios.get(`${env}/api/blogs?populate=deep`).then(function (response) {
      // console.log("llll", response?.data?.data);
      dispatch(Actions.BlogContentAction(response?.data?.data));
    });
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
      useClassNames: true,
      animatedClassName: "animated",
    });
    Aos.refresh();
  });

  useEffect(() => {
    if (Object.keys(HomeData).length === 0) {
      getHomePageData();
    }

    if (Object.keys(BlogPages).length === 0) {
      getBlogContent();
    }
  }, []);

  return (
    <div>
      <Toaster />

      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/products" element={<TelecomPage />}></Route>
          <Route path="/smartcity" element={<SmartCityPage />}></Route>
          <Route path="/blog" element={<BlogPage />}></Route>
          <Route path="/blog/:key" element={<IndividualBlog />}></Route>
          <Route path="/whitepaper" element={<Whitepaper />}></Route>
          <Route
            path="/whitepaper/:key"
            element={<WhitePaperIndividualPage />}
          ></Route>

          <Route path="/life@netstratum" element={<CareerPage />}></Route>
          <Route path="/careers" element={<CareerPage />}></Route>
          <Route path="/careerapply" element={<CareerIndividualPage />}></Route>
          <Route
            path="/careerapply/:key"
            element={<CareerIndividualPage />}
          ></Route>
          <Route path="/nsep" element={<NsepPage />}></Route>
          <Route path="/contact-us" element={<ContactPage />}></Route>
          <Route path="/agrotech" element={<AgroTechPage />}></Route>
          <Route path="/iot" element={<IoTdDevelopmentPage />}></Route>
          <Route path="/iot/netfarm" element={<FarmToPlatePage />}></Route>
          <Route
            path="/iot/offshorewind"
            element={<OffshoreWindpage />}
          ></Route>
          <Route
            path="/iot/bargesolutions"
            element={<SafetyAndMonitoringForBargesPage />}
          ></Route>
          <Route
            path="/product-development"
            element={<ProductDevelopmentPage />}
          ></Route>
          <Route
            path="/product-development/application-development"
            element={<ApplicationDevelopmentPage />}
          ></Route>
          <Route
            path="/product-development/ecommerce-development"
            element={<EcommerceDevelopmentPage />}
          ></Route>
          <Route
            path="/product-development/cloud-services"
            element={<CloudServicesPage />}
          ></Route>
          <Route
            path="/product-development/qualityassurance&testing"
            element={<QualityAssurancePage />}
          ></Route>
          <Route
            path="/product-development/telecom-system"
            element={<TelecomSystemPage />}
          ></Route>
          <Route
            path="/product-development/it-consulting"
            element={<ItConsultingPage />}
          ></Route>
          <Route
            path="/digital-transformation"
            element={<DigitalTransfromationPage />}
          ></Route>
          <Route
            path="/it-consulting"
            element={<ItConsultingHomePage />}
          ></Route>
          <Route
            path="/cloud-solutions"
            element={<CloudSolutionsPage />}
          ></Route>
          <Route
            path="/devopsandintegration"
            element={<DevopsandIntegrationPage />}
          ></Route>
          <Route
            path="/life@netstratum/ethnicday"
            element={<EthnicDayPage />}
          ></Route>
          <Route path="/company" element={<CompanyPage />}></Route>
          <Route path="/finance" element={<FinancePage />}></Route>
          <Route path="/data-analytics" element={<DataAnalyticsPage />}></Route>
          <Route
            path="/lp/website-development"
            element={<WebsiteDevelopmentLandingPage />}
          ></Route>
          <Route
            path="/lp/mobile-app-development"
            element={<MobileAppDevelopmentLandingPage />}
          ></Route>
          <Route path="/wikipeedika" element={<WikipeedikaPage />}></Route>
          <Route
            path="/wikipeedika/:key"
            element={<WikipeedikaIndividualBlogPage />}
          ></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/ncs" element={<NcsPage />}></Route>
          <Route path="/ncs/:key" element={<NcsIndividualPage />}></Route>
          <Route path="/ncs/contact" element={<NcsContact />}></Route>
          <Route path="/paystratum/contact" element={<NcsContact />}></Route>
          <Route
            path="/finance/projectcommunitycommerce"
            element={<PeojectCommunityCommerce />}
          ></Route>

          {/* <Route path="/merchandize" element={<Merchandize />}></Route>
          <Route
            path="/merchandize/success/:checkoutSessionId?"
            element={<Merchandize />}
          ></Route>

          <Route
            path="/merchandize/:key?/:checkoutSessionId?"
            element={<MerchandizeIndividualPage />}
          ></Route> */}
        </Routes>
        <Suspense fallback={<Loader />}>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
