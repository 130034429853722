
//  export const env ="http://localhost:1337";
//  export const env="http://192.168.1.43:1337"
//  export const env="http://192.168.29.151:1337"

 

//  export const env ="http://134.195.41.189:1337";

//  export const env ="http://159.203.186.4:1337";
// export const env="https://us3-test.netstratum.com/api";
// 
// export const env="https://206.189.186.202:1337"


 
 

 export const env ="https://netstratum.com/api"