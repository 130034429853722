import { Dropdown } from "flowbite-react";
import React, { useEffect } from "react";
import { Search } from "./SearchHome";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import Iconmap from "./Iconmap";
import { NavHashLink } from "react-router-hash-link";
import { useRef } from "react";

const Navbar = () => {
  const location = useLocation();
  // console.log("locationn", location)
  let navigate = useNavigate();
  const catMenu: any = useRef(null);
  const mobileMenu: any = useRef(null);

  const [expertiseDropdown, setexpertiseDropdown] = useState<any>(false);

  const closeOpenMenus = (e: any) => {
    if (
      catMenu.current &&
      expertiseDropdown &&
      !catMenu.current.contains(e.target) &&
      e.target.id !== "navbarButtonFalse"
    ) {
      setexpertiseDropdown(false);
    }
  };

  const closeMobileMenus = (e: any) => {
    if (
      mobileMenu.current &&
      dropdownOpen &&
      !mobileMenu.current.contains(e.target) &&
      e.target.id !== "mobileCloseIcon"
    ) {
      setdropdownOpen(false);
    }
  };
  document.addEventListener("mousedown", closeMobileMenus);

  document.addEventListener("mousedown", closeOpenMenus);

  const [dropdownOpen, setdropdownOpen] = useState(false);

  const [expertiseMobileDropdown, setexpertiseMobileDropdown] = useState(false);
  const [scrollToTopFlag, setscrollToTopFlag] = useState(false);

  // console.log("width", width)

  document.getElementsByTagName("body")[0].onresize = () => {
    let width = window.innerWidth;
    if (width < 640) {
      setexpertiseDropdown(false);
    }
  };

  const navigateToID = () => {
    const getInTouch = document.getElementById("getInTouch");
    if (getInTouch) {
      getInTouch.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const handleScrollToTop = () => {
  //     var rootElement = document.documentElement
  //     var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
  //     console.log("jnknknknkjnk", scrollTotal)
  //     if ((rootElement.scrollTop / scrollTotal) > 0.40) {
  //         setscrollToTopFlag(true)
  //         console.log("scroll to top")
  //     } else {
  //         setscrollToTopFlag(false)
  //         console.log("scroll to top")

  //     }
  // }

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 500
        ? setscrollToTopFlag(true)
        : setscrollToTopFlag(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);

  const scrollToTop = () => {
    console.log("njjj");
    window.scrollTo({
      top: 0,
      left: 1,
      behavior: "smooth",
    });
  };
  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 0;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <div
        id="navbar"
        className={`sticky top-0 z-[400] w-full ${
          location?.pathname === "/privacy-policy" && "hidden"
        }  text-black-75 bg-white px-10 flex flex-row h-14 shadow-md  md:px-16 ${
          expertiseDropdown ? null : "shadow-md"
        }   items-center  `}
      >
        <svg
          role="button"
          aria-label="home"
          onClick={() => {
            navigate("/home");
          }}
          className="cursor-pointer flex-shrink-0  "
          width="135"
          height="22"
          viewBox="0 0 135 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.4902 8.82892H37.0563C38.9084 8.82892 39.3026 9.88512 39.3026 10.7777V19.9264H40.9241V10.1603C40.9241 8.16693 39.9274 7.23718 37.7852 7.23718H33.7687C31.6117 7.23718 30.6001 8.16693 30.6001 10.1603V19.9264H32.2216V10.7777C32.2141 9.89255 32.6158 8.82892 34.4902 8.82892Z"
            fill="#F15A29"
          />
          <path
            d="M43.0884 16.624C43.0884 17.8289 43.4157 18.6917 44.0925 19.2496C44.6206 19.6959 45.476 19.9264 46.6363 19.9264H53.5165V18.2752H47.0528C46.3165 18.2752 45.7958 18.1488 45.4685 17.881C44.9999 17.524 44.7619 16.9289 44.7619 16.1182V15.5083L53.5165 11.9454V10.4802C53.5165 8.26363 52.5347 7.23718 50.4297 7.23718H46.4132C44.1446 7.23718 43.0884 8.219 43.0884 10.324V16.624ZM44.7619 10.8669C44.7619 9.93718 45.1785 8.82892 47.1718 8.82892H49.6933C51.538 8.82892 51.8132 9.96693 51.8132 10.6512V11.0826L44.7619 13.9983V10.8669Z"
            fill="#F15A29"
          />
          <path
            d="M60.2332 7.2372H57.3101V2.52893H55.6812V19.9265H57.3101V8.82893H60.2332V7.2372Z"
            fill="#F15A29"
          />
          <path
            d="M71.8141 17.1074V15.4339C71.8141 14.638 71.5686 13.9983 71.0629 13.4702C70.5645 12.9496 69.9397 12.6892 69.1513 12.6892H66.1686C64.3835 12.6892 64.0116 11.7595 64.0116 10.9785V10.443C64.0116 9.91487 64.2496 9.46859 64.7108 9.15619C65.0306 8.94049 65.4918 8.82892 66.1091 8.82892H71.8067V7.23718H65.4918C64.562 7.23718 63.8777 7.40082 63.4612 7.72809C62.7397 8.29338 62.3901 8.99999 62.3901 9.89999V11.6851C62.3901 12.5107 62.6877 13.1504 63.305 13.6413C63.8703 14.1099 64.5992 14.3405 65.4769 14.3405H68.2959C69.4637 14.3405 70.1034 14.9504 70.1034 16.0438C70.1034 16.7876 70.0067 17.2934 69.8133 17.5983C69.5455 18.0446 69.0248 18.2752 68.2736 18.2752H62.3976V19.9264H68.8984C69.4488 19.9264 69.8653 19.8818 70.1331 19.8074C70.4381 19.7033 70.743 19.4727 71.048 19.1306C71.5686 18.5355 71.8141 17.8661 71.8141 17.1074Z"
            fill="#F15A29"
          />
          <path
            d="M78.5305 7.2372H75.6073V2.52893H73.9858V19.9265H75.6073V8.82893H78.5305V7.2372Z"
            fill="#F15A29"
          />
          <path
            d="M83.4175 9.01487C83.596 8.94049 83.9977 8.85123 85.3365 8.83636V7.23718H83.9531C81.7365 7.23718 80.7026 8.27107 80.7026 10.4802V19.9264H82.3241V11.0752C82.3241 9.98925 82.6886 9.29751 83.4175 9.01487Z"
            fill="#F15A29"
          />
          <path
            d="M97.9515 10.2942C97.9515 8.21156 96.873 7.23718 94.5523 7.23718H87.4937V8.82892H94.2399C95.073 8.82892 95.6457 9.08181 95.9581 9.57272C96.1887 9.92974 96.3077 10.5025 96.33 11.3281L96.3375 11.6554H90.5507C89.5837 11.6554 88.8474 11.9157 88.297 12.4438C87.754 12.9719 87.4937 13.7008 87.4937 14.6603V17.0554C87.4937 17.9926 87.7837 18.6992 88.3937 19.2049C88.9515 19.6884 89.7251 19.9339 90.692 19.9339H95.0507C95.9581 19.9339 96.6573 19.6661 97.1705 19.1157C97.6986 18.5653 97.9515 17.8512 97.9515 16.9289V10.2942ZM96.33 16.4678C96.33 17.6355 95.6457 18.2826 94.4036 18.2826H91.1011C90.5581 18.2826 90.0895 18.119 89.7102 17.7917C89.316 17.457 89.1151 17.0033 89.1151 16.4454V15.1438C89.1151 13.8719 89.8143 13.1727 91.0713 13.1727H95.4746C96.2705 13.1727 96.33 13.7454 96.33 13.9165V16.4678Z"
            fill="#F15A29"
          />
          <path
            d="M104.675 7.2372H101.745V2.52893H100.123V19.9265H101.745V8.82893H104.675V7.2372Z"
            fill="#F15A29"
          />
          <path
            d="M117.298 7.23718H115.676V16.2595C115.676 17.1818 115.267 18.2826 113.318 18.2826H110.856C109.986 18.2826 109.406 18.119 109.071 17.7917C108.669 17.3975 108.469 16.8694 108.469 16.2372V7.23718H106.847V16.8917C106.847 18.9669 107.888 19.9264 110.12 19.9264H114.032C116.264 19.9264 117.305 18.9595 117.305 16.8917V7.23718H117.298Z"
            fill="#F15A29"
          />
          <path
            d="M129.957 8.82891C131.571 8.82891 132.01 8.91073 132.203 8.98511C132.739 9.16362 133.379 9.65453 133.379 10.9636V19.9264H135V10.4281C135 9.42395 134.732 8.62065 134.212 8.06279C133.684 7.50494 132.977 7.22974 132.047 7.22974H129.994C128.908 7.22974 128.098 7.63883 127.517 8.47932L127.25 8.8661L126.989 8.47188C126.669 7.98841 126.335 7.66114 125.993 7.49007C125.658 7.31899 125.16 7.22974 124.542 7.22974H122.46C120.414 7.22974 119.455 8.24131 119.455 10.4206V19.919H121.076V10.9636C121.076 9.58759 121.82 8.82891 123.181 8.82891C124.736 8.82891 125.189 8.93304 125.398 9.00742C126.089 9.2975 126.431 9.96693 126.431 11.0082V19.919H128.053V11.0157C128.053 10.3091 128.179 9.81073 128.447 9.49833C128.841 9.05949 129.34 8.82891 129.957 8.82891Z"
            fill="#F15A29"
          />
          <path
            d="M15.9842 3.88267C15.6271 3.62234 15.3891 3.48846 15.0172 3.27275C14.3404 3.0273 13.6114 3.38432 13.3809 4.06862C13.4106 3.97193 14.0875 2.14961 14.1395 1.99341C14.4073 1.26449 15.1883 1.03391 15.761 1.24217C18.1709 2.45457 21.1238 5.16201 21.6891 9.71408C21.6817 10.4504 21.0866 10.986 20.4395 10.9785C20.1941 10.9785 20.0379 10.9785 19.6214 10.9785C19.599 7.73556 17.9329 5.29589 15.9842 3.88267Z"
            fill="#EF4136"
          />
          <path
            d="M15.7837 3.73393L13.4482 10.1529C13.4482 10.1529 13.2102 10.9488 12.273 10.9711C11.4102 10.9711 10.8672 10.9711 10.8672 10.9711C10.8672 10.9711 13.225 4.47773 13.3738 4.07608C13.6788 3.18352 14.5341 3.07195 15.025 3.26534C15.2482 3.39922 15.5383 3.57029 15.7837 3.73393Z"
            fill="url(#paint0_linear_6_4)"
          />
          <path
            d="M20.8564 11.9529C20.3208 11.9529 8.41258 11.9529 8.41258 11.9529C7.76547 11.9677 6.84316 12.1909 6.14399 13.1355C5.35556 14.1991 5.66052 15.419 5.77952 15.7091C5.65308 15.2628 5.80184 14.7421 6.11423 14.452C6.36713 14.2215 6.67209 14.095 6.98448 14.095L20.358 14.0876C21.1242 14.0876 21.4737 13.4405 21.5184 13.3066C21.6225 12.8529 21.7341 11.9826 21.7489 11.6107C21.3919 11.9157 21.087 11.9529 20.8564 11.9529Z"
            fill="#F7941E"
          />
          <path
            d="M7.21489 0.714101C8.18183 0.371952 9.08927 0.208316 9.63224 0.15625C9.63224 0.15625 6.12894 9.75873 6.00249 10.1158C5.70497 10.9414 4.95373 10.986 4.81241 10.986C4.66365 10.986 3.41406 10.986 3.41406 10.986C3.41406 10.986 6.91737 1.36121 6.97687 1.1827C7.05869 0.959556 7.09588 0.8703 7.21489 0.714101Z"
            fill="#808285"
          />
          <path
            d="M13.2842 0.357043C13.2842 0.357043 9.81061 9.90002 9.72136 10.1306C9.60979 10.4356 9.26764 10.9488 8.55359 10.9785C8.1445 10.9785 7.12549 10.9785 7.12549 10.9785C7.12549 10.9785 10.7404 1.04134 10.785 0.952085C10.904 0.602498 11.2685 0.200845 11.8263 0.126465C12.2726 0.156217 12.8974 0.267787 13.2842 0.357043Z"
            fill="#808285"
          />
          <path
            d="M18.6471 15.4564C17.7769 15.4564 8.44216 15.4564 8.44216 15.4564C7.98844 15.4564 7.75042 15.0696 7.75042 14.7795C7.75042 14.4374 8.01819 14.0952 8.44216 14.0952H6.97687C6.58265 14.0952 6.30001 14.2663 6.08431 14.4746C5.70497 14.839 5.63803 15.4266 5.81654 15.8431C6.40414 17.1894 7.57191 17.5985 8.46447 17.5985C9.34216 17.5985 16.4603 17.606 16.4603 17.606C17.0256 17.6283 17.5389 18.0374 17.6504 18.6398C17.7843 19.3613 17.3008 19.7704 17.1372 19.8969C18.0372 19.2646 18.8703 18.4762 19.6215 17.4721C20.2686 16.4977 19.5248 15.4564 18.6471 15.4564Z"
            fill="url(#paint1_linear_6_4)"
          />
          <path
            d="M17.6579 18.6322C17.5686 18.1041 17.1372 17.6876 16.5793 17.6132C12.6744 20.8413 8.77686 19.5471 7.87686 19.2124C6.2405 18.6099 4.84959 17.5835 3.83058 16.1851C2.87107 14.8686 2.46942 13.5446 2.35041 12.9793C2.25372 12.5033 1.82975 11.9231 1.01901 11.9529C0.818182 11.9603 0.453719 12.0124 0 11.6182C0.0966942 13.5 0.743802 15.2107 1.39835 16.3487C3.51074 20.0157 7.08099 21.7562 10.5248 21.8678C10.8074 21.8826 12.2727 21.8975 13.7083 21.5033C15.2405 21.0868 16.4008 20.4173 17.1669 19.8744C17.2636 19.8 17.7843 19.3835 17.6579 18.6322Z"
            fill="url(#paint2_linear_6_4)"
          />
          <path
            d="M5.39265 1.55457C5.39265 1.55457 2.78935 8.65787 2.4472 9.63969C2.20918 10.324 2.08273 10.4653 2.11992 11.2612C1.94885 11.1273 1.72571 10.986 1.30918 10.9711C0.743892 10.9562 0.438933 10.6438 0.305049 10.4653C-0.0296622 10.019 0.059594 9.56531 0.067032 9.49837C0.476123 6.5306 2.16455 3.47357 5.39265 1.55457Z"
            fill="#808285"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6_4"
              x1="13.3259"
              y1="10.9799"
              x2="13.3259"
              y2="3.19416"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.00492611" stopColor="#EF4136" />
              <stop offset="0.4378" stopColor="#EF4136" />
              <stop offset="0.468" stopColor="#EF4136" />
              <stop offset="0.5725" stopColor="#EA3F35" />
              <stop offset="0.6632" stopColor="#E13D33" />
              <stop offset="0.7487" stopColor="#D4392F" />
              <stop offset="0.8307" stopColor="#C1332A" />
              <stop offset="0.8818" stopColor="#B22E25" />
              <stop offset="0.8985" stopColor="#B22E25" />
              <stop offset="1" stopColor="#B22E25" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_6_4"
              x1="6.97016"
              y1="12.8605"
              x2="18.4577"
              y2="19.4929"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0344828" stopColor="#972F1E" />
              <stop offset="0.0602745" stopColor="#A23B20" />
              <stop offset="0.2474" stopColor="#F7941E" />
              <stop offset="1" stopColor="#F7941E" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_6_4"
              x1="2.93625"
              y1="19.0322"
              x2="14.2149"
              y2="12.5205"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F7941E" />
              <stop offset="0.6995" stopColor="#F7941E" />
              <stop offset="1" stopColor="#972F1E" />
            </linearGradient>
          </defs>
        </svg>
        {scrollToTopFlag && (
          // top-[900%] md:top-[1400%] lg:top-[1500%] left-[80%]  sm:left-[90%]
          <svg
            role="button"
            onClick={() => {
              scrollToTop();
            }}
            className=" fixed bottom-4 right-4 md:bottom-24 md:right-20"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_1728_4645)">
              <rect
                x="4"
                width="32"
                height="32"
                rx="16"
                fill="#F7941E"
                shape-rendering="crispEdges"
              />
              <mask
                id="mask0_1728_4645"
                maskUnits="userSpaceOnUse"
                x="11"
                y="7"
                width="18"
                height="18"
              >
                <rect x="11" y="7" width="18" height="18" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1728_4645)">
                <path
                  d="M19.4376 21.7115V12.4432L15.0789 16.8019L14.2886 16L20.0001 10.2885L25.7116 16L24.9212 16.8019L20.5626 12.4432V21.7115H19.4376Z"
                  fill="white"
                />
              </g>
              <rect
                x="4.5"
                y="0.5"
                width="31"
                height="31"
                rx="15.5"
                stroke="white"
                stroke-opacity="0.5"
                shape-rendering="crispEdges"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_1728_4645"
                x="0"
                y="0"
                width="40"
                height="40"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1728_4645"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1728_4645"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        )}
        {location?.pathname !== "/lp/website-development" &&
        location?.pathname !== "/lp/mobile-app-development" ? (
          <>
            <div className="ml-auto sm:hidden cursor-pointer">
              {dropdownOpen ? (
                <>
                  <svg
                    id="mobileCloseIcon"
                    onClick={() => {
                      setdropdownOpen(!dropdownOpen);
                    }}
                    fill="#404041"
                    width="25"
                    height="25"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m14.41 3.27-.82-.94L8 7.17 2.41 2.33l-.82.94L7.05 8l-5.46 4.73.82.94L8 8.83l5.59 4.84.82-.94L8.95 8l5.46-4.73z" />
                  </svg>
                  <div
                    ref={mobileMenu}
                    className=" rounded-[10px] shadow-lg  w-full absolute top-[60px] left-0"
                  >
                    <div className="bg-white flex  flex-col items-center justify-center rounded-[10px]   ">
                      <div
                        id="expertise"
                        onClick={() => {
                          setexpertiseMobileDropdown(!expertiseMobileDropdown);
                        }}
                        className="font-bold text-base px-4 py-3 bg-gray-200 rounded-[10px] mb-[1px] mx-auto w-[99%] flex justify-start"
                      >
                        Expertise
                        <svg
                          role="button"
                          className={`${
                            expertiseMobileDropdown
                              ? "ml-auto my-auto rotate-180"
                              : "ml-auto my-auto"
                          }`}
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.00007 5.28752L0.762573 1.03127L1.55007 0.243774L5.00007 3.69377L8.45007 0.243774L9.23757 1.03127L5.00007 5.28752Z"
                            fill="#1C1B1F"
                          />
                        </svg>
                      </div>
                      {expertiseMobileDropdown ? (
                        <div className="grid grid-cols-2 grid-rows-3 w-full ">
                          <div
                            onClick={() => {
                              navigate("/digital-transformation");
                              setdropdownOpen(false);
                            }}
                            className=" flex flex-col items-center gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="transform" />
                            Digital Transformation
                          </div>
                          <div
                            onClick={() => {
                              navigate("/product-development");
                              setdropdownOpen(false);
                            }}
                            className="  flex flex-col items-center gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="cube" />
                            Product Development
                          </div>
                          <div
                            onClick={() => {
                              navigate("/iot");
                              setdropdownOpen(false);
                            }}
                            className="  flex flex-col items-center gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="iot" />
                            IoT Development
                          </div>
                          <div
                            onClick={() => {
                              navigate("/it-consulting");
                              setdropdownOpen(false);
                            }}
                            className="  flex flex-col items-center gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="itconsulting" />
                            IT Consulting
                          </div>
                          <div
                            onClick={() => {
                              navigate("/cloud-solutions");
                              setdropdownOpen(false);
                            }}
                            className="  flex flex-col items-center gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="cloud" />
                            Cloud Solutions
                          </div>
                          <div
                            onClick={() => {
                              navigate("/devopsandintegration");
                              setdropdownOpen(false);
                            }}
                            className="  flex flex-col items-center gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="Devops" />
                            DevOps & Integration
                          </div>
                          <div
                            onClick={() => {
                              navigate("/finance");
                              setdropdownOpen(false);
                            }}
                            className="  flex flex-col items-center  gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="finance" />
                            Finance
                          </div>
                          <div
                            onClick={() => {
                              navigate("/data-analytics");
                              setdropdownOpen(false);
                            }}
                            className="  flex flex-col items-center  gap-2 p-2 text-xs border-b border-r"
                          >
                            <Iconmap type="dataanalytics" />
                            Data Analytics
                          </div>
                        </div>
                      ) : null}
                      <div
                        onClick={() => {
                          setdropdownOpen(false);
                          navigate("/company");
                        }}
                        className=" cursor-pointer  font-bold text-base p-2 px-4 py-3 bg-gray-200 rounded-[4px] mb-[1px] w-[99%] flex justify-start"
                      >
                        Company
                      </div>
                      <div
                        onClick={() => {
                          navigate("/products");
                          setdropdownOpen(false);
                        }}
                        className="rounded-[10px] cursor-pointer font-bold text-base p-2 px-4 py-3 bg-gray-200  w-[99%] flex justify-start  "
                      >
                        Products
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <svg
                  onClick={() => {
                    setdropdownOpen(!dropdownOpen);
                  }}
                  className=""
                  height="25"
                  width="25"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 18L20 18"
                    stroke="#404041"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M4 12L20 12"
                    stroke="#404041"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M4 6L20 6"
                    stroke="#404041"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </div>
            {expertiseDropdown ? (
              <div
                ref={catMenu}
                id="navbarButtonFalse"
                onClick={(e: any) => {
                  setexpertiseDropdown(false);
                  console.log("hadfjafh", e.target.id);
                  console.log("e.tergate", e.target, expertiseDropdown);
                }}
                className="cursor-pointer  ml-[3%]   hidden relative sm:flex   text-base font-custom m-3"
              >
                Expertise
                <svg
                  role="button"
                  className="ml-2  rotate-180 my-auto"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.00007 5.28752L0.762573 1.03127L1.55007 0.243774L5.00007 3.69377L8.45007 0.243774L9.23757 1.03127L5.00007 5.28752Z"
                    fill="#1C1B1F"
                  />
                </svg>
              </div>
            ) : (
              <div
                id="navbarButton"
                onClick={(e: any) => {
                  setexpertiseDropdown(true);
                  console.log("hadfjafh", e.target.id);
                  console.log("e.tergate", e.target, expertiseDropdown);
                }}
                className=" cursor-pointer  ml-[3%]   hidden relative sm:flex   text-base font-custom m-3"
              >
                <div className=" flex items-center">
                  Expertise
                  <svg
                    role="button"
                    className="ml-2 my-auto"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.00007 5.28752L0.762573 1.03127L1.55007 0.243774L5.00007 3.69377L8.45007 0.243774L9.23757 1.03127L5.00007 5.28752Z"
                      fill="#1C1B1F"
                    />
                  </svg>
                </div>
              </div>
            )}
            <div
              onClick={() => {
                navigate("/products");
              }}
              className=" cursor-pointer hidden sm:block   text-base font-custom m-3"
            >
              Products
            </div>

            <div
              onClick={() => navigate("/company")}
              className=" cursor-pointer hidden sm:block   text-base font-custom m-3"
            >
              Company
            </div>
            <div className=" text-base font-custom  ml-auto hidden lg:block">
              <Search addClass={""} />
            </div>
          </>
        ) : (
          <button
            onClick={() => navigateToID()}
            data-aos="flip-up"
            className="bg-[#616161] ml-auto text-white border border-[#616161] hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] text-xs md:text-sm lg:text-base rounded-[50px] px-3 font-custom py-2 aos-init aos-animate"
          >
            Get in touch
          </button>
        )}

        {expertiseDropdown ? (
          <div
            ref={catMenu}
            className={`hidden absolute h-[180px]  cursor-pointer left-0 w-screen sm:flex  top-[100%] bg-white ${
              expertiseDropdown ? "shadow-md" : null
            }`}
          >
            <div className="relative  w-[150px] p-5 flex items-center justify-center font-custom h-full">
              <svg
                className="lg:scale-125"
                width="134"
                height="74"
                viewBox="0 0 134 74"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4283 0.0173807C-7.49802 0.614809 -0.507353 28.7064 6.22877 42.6775C29.1378 89.2583 121.24 84.8709 133.537 22.8877C138.631 -2.78767 100.417 7.21544 82.406 13.2728C53.8162 22.8877 50.8362 -0.729405 18.4283 0.0173807Z"
                  fill="#F4F4F4"
                />
              </svg>
              <div className="absolute left-[15%]  font-custom font-bold">
                Expertise
              </div>
              <svg
                className="ml-7 scale-[200%]"
                width="6"
                height="102"
                viewBox="0 0 6 102"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333ZM3 96.3333C1.52724 96.3333 0.333337 97.5272 0.333337 99C0.333338 100.473 1.52724 101.667 3 101.667C4.47276 101.667 5.66667 100.473 5.66667 99C5.66667 97.5272 4.47276 96.3333 3 96.3333ZM2.5 3L2.5 99L3.5 99L3.5 3L2.5 3Z"
                  fill="#F7941E"
                />
              </svg>
            </div>

            <div className="w-full lg:w-full  my-auto grid grid-cols-3 grid-rows-3   font-custom text-base">
              <div
                onClick={() => {
                  navigate("/product-development");
                  setexpertiseDropdown(false);
                }}
                className=" z-[200]  flex items-center p-2 text-sm lg:text-base gap-2 border border-t-0 border-l-0 border-black-75 border-opacity-10 hoverGradient "
              >
                <Iconmap type="cube" />
                <div className=" ">Product Development</div>
              </div>
              <div
                onClick={() => {
                  navigate("/finance");
                  setexpertiseDropdown(false);
                }}
                className="flex items-center p-2 text-sm border-b  border-r lg:text-base gap-2 hoverGradient"
              >
                <Iconmap type="finance" />
                <div className="">Finance</div>
              </div>
              <div
                onClick={() => {
                  navigate("/digital-transformation");
                  setexpertiseDropdown(false);
                }}
                className="flex items-center p-2 text-sm lg:text-base gap-1 xl:gap-2 border-b border-r border-black-75 border-opacity-10 hoverGradient"
              >
                <Iconmap type="transform" />
                <div className="">Digital Transformation</div>
              </div>
              <div
                onClick={() => {
                  navigate("/it-consulting");
                  setexpertiseDropdown(false);
                }}
                className="flex items-center p-2 text-sm lg:text-base gap-2 border-r  border-b border-black-75 border-opacity-10 hoverGradient"
              >
                <Iconmap type="itconsulting" />
                <div className="">IT Consulting</div>
              </div>
              <div
                onClick={() => {
                  navigate("/iot");
                  setexpertiseDropdown(false);
                }}
                className="flex items-center p-2 text-sm lg:text-base gap-2 border-b border-r  border-black-75 border-opacity-10 hoverGradient"
              >
                <Iconmap type="iot" />
                <div className="">IoT Development</div>
              </div>

              <div
                onClick={() => {
                  navigate("/cloud-solutions");
                  setexpertiseDropdown(false);
                }}
                className="flex items-center p-2 text-sm lg:text-base gap-2 border-b border-r border-black-75 border-opacity-10 hoverGradient"
              >
                <Iconmap type="cloud" />
                <div className="">Cloud Solutions</div>
              </div>
              <div
                onClick={() => {
                  navigate("/devopsandintegration");
                  setexpertiseDropdown(false);
                }}
                className="flex items-center p-2 text-sm border-r  border-b lg:text-base gap-2 hoverGradient"
              >
                <Iconmap type="Devops" />
                <div className="">DevOps & Integration</div>
              </div>
              <div
                onClick={() => {
                  navigate("/data-analytics");
                  setexpertiseDropdown(false);
                }}
                className="flex items-center p-2 text-sm border-r  border-b lg:text-base gap-2 hoverGradient"
              >
                <Iconmap type="dataanalytics" />
                <div className="">Data Analytics</div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Navbar;
